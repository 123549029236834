
/* -- 多行省略 -- */
.fontBreak(@line: 1; @lineHeight: 0.24rem) {
	overflow: hidden;
	display: -webkit-box;
	-webkit-box-orient: vertical;
	-webkit-line-clamp: @line;
	line-height: @lineHeight;
}

/* -- 背景填充(主用图片) -- */
.bgSet(@size: cover) {
	background-position: center center;
	background-size: @size;
	background-repeat: no-repeat;
}
* {
	margin: 0;
	padding: 0;
	box-sizing: border-box;
	-webkit-touch-callout: none;
	-webkit-tap-highlight-color: transparent;
}

.img-lazy {
	background-color: #F0F0F0;
}
#root{
	min-width: 100vw;
	min-height: 100vh;
}

html, body {
	width: 100%;
	min-width: 320px;
	//height: 100%;
	overflow-x: hidden;
	min-height: 100vh;
	background-color: #FFFFFF;
}

body, #__nuxt, #__layout, .body-layout {
	flex: auto;
	width: 100%;
	min-height: 100vh;
	overflow: hidden;
}

img {
	width: 100%;
	vertical-align: top;
	border: 0;
	-webkit-user-drag: none;
	-webkit-user-modify: read-only;
	-webkit-user-select: none;
	display: inline-block;
}

a {
	text-decoration: none;
	color: inherit;
}

ul li,
ol li {
	list-style: none;
}

input, textarea, button {
	border: none;
	outline: none;
}

::-webkit-scrollbar {
	width: 0;
	height: 0;
	-webkit-appearance: none;
	background-color: #314559;
}

::-webkit-scrollbar-track {
	width: 0;
	//background-color: transparent;
	background-color: #FFFFFF;
}

::-webkit-scrollbar-thumb {
	border-radius: 0;
	background-color: #AAAAAA;
}


textarea {
	resize: none;
}

input:disabled, textarea:disabled {
	background-color: #FFFFFF;
}

input::-webkit-search-decoration,
input::-webkit-search-cancel-button {
	display: none;
}

// FormComponent
.ant-form-item-control-input-content {
	width: 100% !important;
	display: flex !important;
	align-items: center !important;
}

.custom-input,
.custom-textarea {
	border: 0 !important;
	font-size: 0.26rem !important;

	&:focus {
		outline: none !important;
		box-shadow: unset !important;
	}
}

.custom-textarea {
	padding: 0.2rem 0 !important;
	height: 2rem !important;
	line-height: 0.6rem !important;
}

.manage-create-button,
.manage-remove-button {
	width: 100vw;
	position: fixed;
	left: 0;
	bottom: 0;
	display: flex;
	align-items: center;
	justify-content: center;
	height: 0.98rem;
	font-size: 0.28rem;
	font-weight: 500;
	line-height: 0.4rem;
}

.manage-create-button {
	color: #FFFFFF;
	background-color: #1AC6D4;
}

.manage-remove-button {
	color: #E35A5A;
	background-color: #FFFFFF;
}

.ant-input[disabled] {
	color: #333333 !important;
	background-color: #FFFFFF !important;
}

//@media screen and (orientation: portrait) {

.prescription-layout-container{
	width: 133.4vw;
	height: 64vw;
	overflow: auto;
	transform: rotateZ(90deg)scale(1.5)translate(37vw, 0rem);
	transform-origin: 50vw;
}
	.prescription-layout {
		width: 100vh;
		//height: 100vw;
		//transform: rotateZ(90deg)scale(1.5)translate(23vw, 17vw);
		//transform-origin: 50vw;
		overflow: scroll;
		position: absolute;
		left:0;
		top: 0;
	}

	.prescription-container {
		width: 10rem;
		margin-bottom: 0.25rem;

		.table-header {
			width: 100%;
			height: 0.55rem;
			background-color: #1AC6D4;
			color: #FFFFFF;
			display: flex;
			align-items: center;
			justify-content: center;
			font-size: 0.18rem;
			font-weight: 500;
		}

		.table-desc {
			width: 100%;
			height: 0.55rem;
			display: flex;
			align-items: center;
			justify-content: space-between;
			padding: 0 0.18rem;
			background-color: #FFFFFF;

			span {
				font-size: 0.16rem;
				font-weight: 500;
				color: #333333;
			}
		}

		.table-children-header {
			width: 100%;
			height: 0.92rem;
			border-top: 1px solid #DFDFDF;
			border-left: 1px solid #DFDFDF;
			border-bottom: 1px solid #DFDFDF;
			background-color: #FFFFFF;
			display: flex;
			overflow: hidden;

			.table-col-header {
				display: flex;
				flex: auto;
				width: 100%;
				//background-color: pink;
				& > div {
					border-right: 1px solid #DFDFDF;
				}

				div {
					display: flex;
					align-items: center;
					justify-content: center;
					font-size: 0.16rem;
					font-weight: 500;
					color: #333333;
					flex: auto;

					&:nth-child(1) {
						width: 0.92rem;
					}

					&:nth-child(2) {
						width: 2.4rem;
					}

					&:nth-child(3) {
						width: 5.55rem;
						display: flex;
						flex-direction: column;
						//background-color: skyblue;
					}

					&:nth-child(4) {
						width: 1.12rem;
					}
				}
			}

			.table-col-children-header {
				width: 100% !important;
				height: 0.46rem;
				flex: auto;
				border-bottom: 1px solid #DFDFDF;

				&:last-child {
					border-bottom: 0;
				}

				div {
					height: 100%;
					width: 33.333333%;
					flex: none;
					//width: 0.8rem !important;
					border-right: 1px solid #DFDFDF;

					&:last-child {
						border-right: 0;
					}
				}
			}
		}

		.table-body {
			width: 100% !important;
			background-color: #FFFFFF;
			border-bottom: 1px solid #DFDFDF;
			border-right: 1px solid #DFDFDF;
			border-left: 1px solid #DFDFDF;
			display: flex;
			height: 0.55rem;

			> div {
				display: flex;
				align-items: center;
				justify-content: center;
				border-right: 1px solid #DFDFDF;
				flex: none;

				&:nth-child(1) {
					width: 0.92rem;
				}

				&:nth-child(2) {
					width: 2.4rem;
				}

				&:nth-child(3) {
					width: 5.55rem;
					justify-content: flex-start;

					div {
						height: 100%;
						display: flex;
						align-items: center;
						justify-content: center;
						border-right: 1px solid #DFDFDF;

						&:last-child {
							border-right: 0;
						}
					}
				}

				//&:nth-child(3){
				//	width: 1.21rem;
				//}
				//&:nth-child(4){
				//	width: 1.21rem;
				//}
				//&:nth-child(5){
				//	width: 1.21rem;
				//}
				&:nth-child(4) {
					width: 1.12rem;
				}

				&:last-child {
					border-right: 0;
				}
			}
		}

		.ware-row {
			width: 100%;
			height: 0.55rem;
			display: flex;
			border-bottom: 1px solid #DFDFDF;
			background-color: #FFFFFF;

			div {
				display: flex;
				align-items: center;
				justify-content: center;
				border-right: 1px solid #DFDFDF;
				flex: none;

				&:nth-child(1) {
					width: 1.4rem;
				}

				&:nth-child(2) {
					width: 5.4rem;
				}

				&:nth-child(3) {
					width: 1.6rem;
				}

				&:nth-child(4) {
					width: 1.6rem;
				}

				&:last-child {
					border-right: 0;
				}
			}
		}
	}
//}

@media screen and (orientation: landscape) {

	.prescription-layout {
		width: 100vw;
		height: 100vh;
		overflow: scroll;
	}

	.prescription-container {
		//width: 10rem;
		width: 100%;
		margin-bottom: 0.25rem;

		.table-header {
			width: 100%;
			height: 0.55rem;
			background-color: #1AC6D4;
			color: #FFFFFF;
			display: flex;
			align-items: center;
			justify-content: center;
			font-size: 0.18rem;
			font-weight: 500;
		}

		.table-desc {
			width: 100%;
			height: 0.55rem;
			display: flex;
			align-items: center;
			justify-content: space-between;
			padding: 0 0.18rem;
			background-color: #FFFFFF;

			span {
				font-size: 0.16rem;
				font-weight: 500;
				color: #333333;
			}
		}

		.table-children-header {
			width: 100%;
			height: 0.92rem;
			border-top: 1px solid #DFDFDF;
			border-left: 1px solid #DFDFDF;
			border-bottom: 1px solid #DFDFDF;
			background-color: #FFFFFF;
			display: flex;
			overflow: hidden;

			.table-col-header {
				display: flex;
				flex: auto;
				width: 100%;
				//background-color: pink;
				& > div {
					border-right: 1px solid #DFDFDF;
				}

				div {
					display: flex;
					align-items: center;
					justify-content: center;
					font-size: 0.16rem;
					font-weight: 500;
					color: #333333;
					flex: auto;

					&:nth-child(1) {
						width: 0.6rem;
						flex: none;
					}

					&:nth-child(2) {
						width: 1.8rem;
						flex: none;
					}

					&:nth-child(3) {
						width: 3.55rem;
						flex: none;
						display: flex;
						flex-direction: column;
						//background-color: skyblue;
					}

					&:nth-child(4) {
						width: 1.12rem;
					}
				}
			}

			.table-col-children-header {
				width: 100% !important;
				height: 0.46rem;
				flex: auto;
				border-bottom: 1px solid #DFDFDF;

				&:last-child {
					border-bottom: 0;
				}

				div {
					height: 100%;
					width: 33.333333%;
					flex: none;
					//width: 0.8rem !important;
					border-right: 1px solid #DFDFDF;

					&:last-child {
						border-right: 0;
					}
				}
			}
		}

		.table-body {
			width: 100% !important;
			background-color: #FFFFFF;
			border-bottom: 1px solid #DFDFDF;
			border-right: 1px solid #DFDFDF;
			border-left: 1px solid #DFDFDF;
			display: flex;
			height: 0.55rem;

			> div {
				display: flex;
				align-items: center;
				justify-content: center;
				border-right: 1px solid #DFDFDF;
				flex: none;

				&:nth-child(1) {
					width: 0.6rem;
				}

				&:nth-child(2) {
					width: 1.8rem;
				}

				&:nth-child(3) {
					width: 3.55rem;
					justify-content: flex-start;

					div {
						height: 100%;
						display: flex;
						align-items: center;
						justify-content: center;
						border-right: 1px solid #DFDFDF;

						&:last-child {
							border-right: 0;
						}
					}
				}

				//&:nth-child(3){
				//	width: 1.21rem;
				//}
				//&:nth-child(4){
				//	width: 1.21rem;
				//}
				//&:nth-child(5){
				//	width: 1.21rem;
				//}
				&:nth-child(4) {
					width: 1.12rem;
				}

				&:last-child {
					border-right: 0;
				}
			}
		}

		.ware-row {
			width: 100%;
			height: 0.55rem;
			display: flex;
			border-bottom: 1px solid #DFDFDF;
			background-color: #FFFFFF;

			div {
				display: flex;
				align-items: center;
				justify-content: center;
				border-right: 1px solid #DFDFDF;
				flex: none;

				&:nth-child(1) {
					width: 1.4rem;
				}

				&:nth-child(2) {
					width: 3.4rem;
				}

				&:nth-child(3) {
					width: 1.6rem;
				}

				&:nth-child(4) {
					width: 1.6rem;
				}

				&:last-child {
					border-right: 0;
				}
			}
		}
	}
}
